import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "typescript-crash-course"
    }}>{`TypeScript Crash Course`}</h1>
    <p>{`This is meant to be a basic introduction to common types used in TypeScript. This is not necessarily releated to React but will be used as React files are typed.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`const myName: string = 'Aryan';
const myAge: number = 25; // I swear 🤥:

// array of type
const myFavoriteLanguages: string[] = ['JavaScript', 'Python', 'Java'];

// string literals (must be one of these EXACT values)
const seasons: 'summer' | 'fall' | 'winter' | 'spring' = 'winter';

// object with specific properties
const car: {
  make: string;
  model: string;
  yearReleased: number;
  hasAirbags?: boolean; // the question mark means this property is optional
} = {
  make: 'Honda',
  model: 'Civic',
  yearReleased: 1994,
}; // fun fact, this was my first car!

// you can also define the type separately
type Car = {
  make: string;
  model: string;
  yearReleased: number;
  hasAirbags?: boolean;
};
const someFancierCar: Car = {
  make: 'Jaguar',
  model: 'XJ',
  yearReleased: 2019,
  hasAirbags: true,
};

// array of objects
const garage: Car[] = [car, someFancierCar];

// function without call signature
const callback: Function = () => 'ohai!';

// function with call signature
const callbackWithStrictSignature: (name: string) => string = () => \`hai again, \${name}!\`;
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      